import React, { useRef, useState } from 'react';
import "./signup.css";
import { useAuth } from "../../contexts/AuthContext";
import { useDatabase } from "../../contexts/DatabaseContext";
import { Link, useNavigate } from "react-router-dom";

export default function SignUp() {
  // Refs for form inputs
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const usernameRef = useRef();

  // Auth and Database hooks
  const { signup } = useAuth();
  const { checkUsernameExists, saveUsername } = useDatabase();

  // State management
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [usernameTaken, setUsernameTaken] = useState(false);
  const navigate = useNavigate();

  // Handle form submission
  async function handleSubmit(e) {
    e.preventDefault();

    if (usernameRef.current.value.length < 3) {
        return setError("Username must be at least 3 characters long");
    }

    if (usernameRef.current.value.length > 40) {
      return setError("Username can't be longer than 40 characters.");
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords don't match");
    }

    if (usernameTaken) {
      return setError("Username is already taken");
    }

    try {
      setError('');
      setLoading(true);
      const userCredential = await signup(emailRef.current.value, passwordRef.current.value);
      const user = userCredential.user;
      
      // Update the profile of the new user
      await saveUsername(user.uid, usernameRef.current.value);
      await user.updateProfile({ displayName: usernameRef.current.value });
      
      // ADD IF YOU CHANGE TO VERIFY MAILS
      //alert("Account created! Please check your email to verify your account.");
      
      navigate("/profile");
    } catch {
      setError('Failed to create an account');
    }
    setLoading(false);
  }

  // Handle username change and check availability
  async function handleUsernameChange(e) {
    const newUsername = e.target.value;
    usernameRef.current.value = newUsername;

    // Check if the username is available
    const exists = await checkUsernameExists(newUsername);
    setUsernameTaken(exists);
  }

  return (
    <>
      <div className="signup-card">
        <h1 className="signup-title">Sign Up</h1>
        <form className="signup-form" onSubmit={handleSubmit}>
          <div className="form-group" id="email">
            <label>Email</label>
            <input type="email" ref={emailRef} required className="form-control" />
          </div>
          <div className="form-group" id="username">
            <label>Username</label>
            <input 
              type="text" 
              ref={usernameRef} 
              required 
              className="form-control" 
              onChange={handleUsernameChange}
            />
            <div className="error-container">
                {usernameTaken && <p className="error-message">Username is already taken</p>}
            </div>
          </div>
          <div className="form-group" id="password">
            <label>Password</label>
            <input type="password" ref={passwordRef} required className="form-control" />
          </div>
          <div className="form-group" id="password-confirm">
            <label>Password Confirmation</label>
            <input type="password" ref={passwordConfirmRef} required className="form-control" />
          </div>
          <button disabled={loading} type="submit" className="btn btn-primary signup-button">
            Sign Up
          </button>
        </form>
        <div className="error-container">
          {error && <div className="error-message"><h3>{error}</h3></div>}
        </div>
      </div>
      <div className="login">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </>
  );
}
