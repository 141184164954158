import React, { useState, useEffect } from 'react';
import "./profile.css";
import { useStorage } from "../../contexts/StorageContext";
import { useDatabase } from "../../contexts/DatabaseContext";
import { useAuth } from "../../contexts/AuthContext";

export default function Profile() {
    const { setProfilePic, deleteProfilePic } = useStorage();
    const { currentUser, photoURL, updateProfilePic } = useAuth();
    const { checkUsernameExists, saveUsername } = useDatabase();

    const [photo, setPhoto] = useState(null);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [originalUsername, setOriginalUsername] = useState("");
    const [usernameTaken, setUsernameTaken] = useState(false);
    const [usernameTooShort, setUsernameTooShort] = useState(false);
    const [usernameTooLong, setUsernameTooLong] = useState(false);
    const types = ["image/png", "image/jpeg"];
    const defaultImageURL = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

    // Fetch the username from current user
    useEffect(() => {
        if (currentUser?.displayName) {
            setUsername(currentUser.displayName);
            setOriginalUsername(currentUser.displayName);
        }
    }, [currentUser]);

    // Handle profile-pic file input change
    function handleChange(e) {
        let selected = e.target.files[0];
        if (selected && types.includes(selected.type)) {
            setPhoto(selected);
        } else {
            setPhoto(null);
        }
    }

    // Handle profile picture update
    function handleClick() {
        setLoading(true);
        setProfilePic(photo, setLoading);
    }

    // Handle profile picture deletion
    async function handleDeletePic() {
        const confirmDeletion = window.confirm("Are you sure you want to delete your profile picture?");
        if (confirmDeletion) {
            setLoading(true);

            try {
                await deleteProfilePic(currentUser.uid);
                alert("Profile picture deleted successfully!");
                await updateProfilePic(currentUser, defaultImageURL);
            } catch (error) {
                console.error("Error deleting profile picture:", error);
                alert("Failed to delete profile picture. Please try again.");
            } finally {
                setLoading(false);
            }
        }
    }

    // Handle username input change and validate that it's avalible 
    async function handleUsernameChange(e) {
        const newUsername = e.target.value;
        setUsername(newUsername);

        setUsernameTooShort(newUsername.length < 3);
        setUsernameTooLong(newUsername.length > 40);

        if (newUsername.length >= 3 && newUsername.length <= 40 && newUsername !== currentUser.displayName) {
            const exists = await checkUsernameExists(newUsername);
            setUsernameTaken(exists);
        } else {
            setUsernameTaken(false);
        }
    }

    async function handleUsernameSave() {
        if (!usernameTooShort && !usernameTooLong && !usernameTaken && username.trim() !== "") {
            try {
                await saveUsername(currentUser.uid, username);
                await currentUser.updateProfile({ displayName: username });
                alert("Username updated successfully!");
                setOriginalUsername(username);
            } catch (error) {
                console.error("Error updating username:", error);
                alert("Failed to update username. Please try again.");
            }
        } else if (usernameTooShort) {
            alert("Username must be at least 3 characters long.");
        } else if (usernameTooLong) {
            alert("Username can't be longer than 40 characters.");
        }
    }

    return (
        <div className="profile-div">
            <h1>Profile</h1>
            <div className="profile-section">
                <img className="ppic" src={photoURL} alt="" />
                <h4>Profile Picture</h4>
                <div className="profile-controls">
                    <input type="file" onChange={handleChange} />
                    <button disabled={loading || !photo} onClick={handleClick}>
                        Update Profile Pic
                    </button>
                    <button className="delete-btn" onClick={handleDeletePic} disabled={loading}>
                        Delete Profile Pic
                    </button>
                </div>
            </div>
            <div className="username-section">
                <h4>Username</h4>
                <input 
                    type="text" 
                    value={username} 
                    onChange={handleUsernameChange} 
                    className={usernameTaken ? 'taken' : ''} 
                />
                {username !== originalUsername && usernameTaken && (
                    <p className="error">Username is already taken</p>
                )}
                <button 
                    disabled={usernameTaken || username.trim() === "" || username === originalUsername} 
                    onClick={handleUsernameSave}
                >
                    Save Username
                </button>
            </div>
            {/*<h4>Liked Posts</h4> 
            <h4>Liked Images</h4> 
            <h4>Liked Blogs</h4>*/}
        </div>
    );
}