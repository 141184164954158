import React, { useState } from 'react';
import { useDatabase } from "../../contexts/DatabaseContext";
import { useAuth } from "../../contexts/AuthContext";
import { Navigate } from 'react-router-dom';
import "./uploadimages.css";

export default function UploadImages() {
  const { uploadImage, admins } = useDatabase();
  const { currentUser } = useAuth();
  const isAdmin = currentUser && admins.includes(currentUser.uid);

  // Local state management
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileURL, setFileURL] = useState('');
  const [description, setDescription] = useState('');
  const [place, setPlace] = useState('');
  const [location, setLocation] = useState('');

  // Redirect non-admin users as this is a private route
  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  // Handle file selection (can only handle a single file at once)
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle place input change in order to allow input of coordinate
  const handlePlaceChange = (event) => {
    setPlace(event.target.value);
    if (!event.target.value) {
      setLocation(''); // Clear location if place is empty
    }
  };

  // Handle file upload to firebase
  const handleUpload = async () => {
    if (selectedFile) {
      setLoading(true);
      try {
        // Handle parsing of coordinates and set them to null if either isn't given correctly
        // TODO: Make it only possible to not enter a non-coordinate separated by a comma
        let [latitude, longitude] = location.split(',').map(coord => parseFloat(coord.trim()));
        if (isNaN(latitude) || isNaN(longitude)) {
          latitude = null;
          longitude = null;
        }

        // Construct image data object
        const imageData = {
            description,
            place,
            latitude,
            longitude,
            userId: currentUser.uid,
            type: "gallery"
        };
        
        const url = await uploadImage(selectedFile, imageData);
        setFileURL(url);

        alert('File uploaded successfully!');
      } catch (error) {
        alert("Error uploading file: " + error.message);
      } finally {
        // Reset form and loading state
        setLoading(false);
        setSelectedFile(null);
        setDescription('');
        setPlace('');
        setLocation('');
      }
    }
  };

  return (
    <div className="upload-images">
      <h1>Upload New Image</h1>
      <p className="optional-note">All fields are optional. Place is required for coordinates</p>
      <input type="file" onChange={handleFileChange} required />
      <input 
        type="text" 
        placeholder="Description" 
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <input 
        type="text" 
        placeholder="Place" 
        value={place}
        onChange={handlePlaceChange}
      />
      <input 
        type="text" 
        placeholder="Latitude, Longitude" 
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        disabled={!place}  // Disable if place is empty
        className={!place ? 'disabled-input' : ''}
      />
      <button onClick={handleUpload} disabled={!selectedFile || loading}>
        {loading ? "Uploading..." : "Upload"}
      </button>
      {fileURL && <p>File uploaded to: <a href={fileURL} target="_blank" rel="noopener noreferrer">{fileURL}</a></p>}
    </div>
  );
}
