import React, { useState } from 'react';
import { useDatabase } from "../../contexts/DatabaseContext";
import { useAuth } from "../../contexts/AuthContext";
import { timestamp, storage } from "../../firebase";
import './createblog.css';
import { Navigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function CreateBlog() {
  const { addBlog, admins } = useDatabase();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState('');
  const [place, setPlace] = useState('');
  const { currentUser } = useAuth();
  const isAdmin = currentUser && admins.includes(currentUser.uid);
  
  // Redirect if the user is a non-admin
  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  function handleBodyChange(value) {
    setBody(value);
  }

  // Handle photo file selection
  function handleChange(e) {
    let selectedFiles = Array.from(e.target.files);
    const types = ["image/png", "image/jpeg"];

    // Filter files to only include images of type png or jpeg
    selectedFiles = selectedFiles.filter(file => types.includes(file.type));

    if (selectedFiles.length > 0) {
      setPhotos([...photos, ...selectedFiles]);
    } else {
      alert("Please select image files (png or jpeg)");
    }
  }

  // Move photo up in the list (the first image is used as a banner/preview image for other pages)
  function handleMoveUp(index) {
    if (index === 0) return;
    const newPhotos = [...photos];
    [newPhotos[index - 1], newPhotos[index]] = [newPhotos[index], newPhotos[index - 1]];
    setPhotos(newPhotos);
  }

  // Move photo down in the list
  function handleMoveDown(index) {
    if (index === photos.length - 1) return;
    const newPhotos = [...photos];
    [newPhotos[index + 1], newPhotos[index]] = [newPhotos[index], newPhotos[index + 1]];
    setPhotos(newPhotos);
  }

  // Delete photo from the list
  function handleDelete(index) {
    const newPhotos = photos.filter((_, i) => i !== index);
    setPhotos(newPhotos);
  }

  // Handle blog submission
  async function handleSubmit(e) {
    e.preventDefault();

    if (!title || !body) {
      alert("Please fill in Title and Body fields");
      return;
    }

    setLoading(true);

    try {
      let photoURLs = [];

      // Extract latitude and longitude from location input
      // TODO: Ensure that the parsed string is a coordinate
      const [latitude, longitude] = location.split(',').map(coord => parseFloat(coord.trim()));

      if (photos.length > 0) {
        // Upload photos to Firebase Storage if photos are selected
        const uploadPromises = photos.map(photo => {
          const photoRef = storage.ref().child(`blog_images/${Date.now()}_${photo.name}`);
          return photoRef.put(photo).then(() => photoRef.getDownloadURL());
        });

        photoURLs = await Promise.all(uploadPromises);
      }

      // Get current date and time in Japan timezone
      const currentDate = new Date().toLocaleDateString('ja-JP', {
        timeZone: 'Asia/Tokyo',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });

      const currentTime = new Date().toLocaleTimeString('ja-JP', {
        timeZone: 'Asia/Tokyo',
        hour: '2-digit',
        minute: '2-digit'
      });

      // Add new blog entry
      await addBlog({
        data: {
          title,
          body,
          date: currentDate,
          time: currentTime,
          createdAt: timestamp,
          photoURLs: photoURLs.length > 0 ? photoURLs : null,  // If no photos uploaded, set photoURLs to null
          place: place,
          latitude: !isNaN(latitude) ? latitude : null,
          longitude: !isNaN(longitude) ? longitude : null,
          userId: currentUser.uid,
          type: "blog"
        }
      });

      // Clear form fields
      setTitle('');
      setBody('');
      setPhotos([]);
      setLoading(false);
      setLocation('');
      setPlace('');

      alert("Blog added successfully!");
    } catch (error) {
      console.error('Error adding blog:', error);
      alert("Failed to add blog. Please try again.");
      setLoading(false);
    }
  }

  return (
    <div className="newblog-container">
      <h1 className="titleNewBlog">Create New Blog</h1>
      <p className="helpNewBlog">Place, coordinates and photos are optional. Place is required for coordinates</p>
      <form onSubmit={handleSubmit}>
        <div>
          <input type="text" placeholder="Title" value={title} onChange={handleTitleChange} required />
        </div>
        <div>
          <ReactQuill   theme="snow" 
          value={body} 
          onChange={handleBodyChange} 
          required 
          className="quill-editor"/>
        </div>
        <input 
          type="text" 
          placeholder="Place" 
          value={place}
          onChange={(e) => setPlace(e.target.value)}
        />
        <input 
          type="text" 
          placeholder="Latitude, Longitude" 
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          disabled={!place}  // Disable if place is empty
          className={!place ? 'disabled-input' : ''}
        />
        <div>
          <label>Photos:</label>
          <input type="file" onChange={handleChange} accept="image/png, image/jpeg" multiple />
        </div>
        <div className="addphoto-container">
          {photos.length > 0 && photos.map((photo, index) => (
            <div key={index} className="preview-photoitem">
              <img src={URL.createObjectURL(photo)} alt="Preview" />
              <button type="button" onClick={() => handleMoveUp(index)} disabled={index === 0}>Up</button>
              <button type="button" onClick={() => handleMoveDown(index)} disabled={index === photos.length - 1}>Down</button>
              <button type="button" onClick={() => handleDelete(index)}>Delete</button>
            </div>
          ))}
        </div>
        <button type="submit" disabled={!title || loading || !body} className="submit-button">Submit</button>
      </form>
    </div>
  );
}
