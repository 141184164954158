import React from 'react'
import './footer.css';
import { FaYoutube, FaInstagram } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

// Functional component for the footer section
export default function Footer() {
  return (
    <footer>
        <div className="footer-container">
            <div className="contactText">
                Contact Me
            </div>
            <div className="social-icons">
                <a href="https://www.youtube.com/channel/UCj7VcZlEdr4nAtsxEi9GCgQ" target="_blank" rel="noopener noreferrer" aria-label="YouTube Channel">
                    <FaYoutube />
                </a>
                <a href="https://www.instagram.com/limmelu" target="_blank" rel="noopener noreferrer" aria-label="Instagram Profile">
                    <FaInstagram />
                </a>
                <a href="mailto:linus.lundblad@gmail.com" aria-label="Email">
                    <MdEmail />
                </a>
            </div>
            <div className="nameText">
                Linus Lundblad <br/> 2024
            </div>
        </div>
    </footer>
  );
};