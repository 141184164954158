import React, { useEffect, useState } from 'react';
import { useDatabase } from "../../contexts/DatabaseContext";
import { Link } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import CommentsSection from '../../components/commentsection/Commentsection';
import "./gallery.css";

export default function Gallery() {
  const { blogs, galleryImages, admins, deleteImageFromGallery } = useDatabase();
  const { currentUser } = useAuth();
  const isAdmin = currentUser && admins.includes(currentUser.uid);

  // State hooks
  const [showBlogImages, setShowBlogImages] = useState(true);
  const [sortOrder, setSortOrder] = useState('newest');
  const [allImages, setAllImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);  // Loading state

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);

      // Combine blog images and gallery images
      const blogImages = showBlogImages ? blogs.flatMap(blog => 
        (blog.data.photoURLs || []).map(url => ({
          url,
          createdAt: blog.data.createdAt.toDate(),
          type: 'blog',
          postId: blog.id
        }))
      ) : [];

      const galleryImgs = galleryImages.map(img => ({ 
        url: img.url, 
        createdAt: img.createdAt.toDate(), 
        type: img.type ? img.type : 'gallery', 
        id: img.id,
        place: img.place,
        longitude: img.longitude,
        latitude: img.latitude,
        description: img.description ? img.description : "",
      }));

      // Combine and sort images
      const combinedImages = [...blogImages, ...galleryImgs];
      const sortedImages = combinedImages.sort((a, b) => 
        sortOrder === 'newest' ? b.createdAt - a.createdAt : a.createdAt - b.createdAt
      );

      setAllImages(sortedImages);
      setLoading(false);  // Set loading to false after data is processed
    };

    fetchImages();
  }, [blogs, galleryImages, showBlogImages, sortOrder]);

  // Open and close modal mode
  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);

  // Handle image deletion
  const handleDeleteImage = async () => {
    if (selectedImage && selectedImage.type === 'gallery') {
      const confirmed = window.confirm("Are you sure you want to delete this image? This action cannot be undone.");
  
      if (confirmed) {
        try {
          await deleteImageFromGallery(selectedImage.id);
          alert("Image deleted successfully!");
          setSelectedImage(null);
        } catch (error) {
          console.error("Error deleting image:", error);
          alert("Failed to delete image. Please try again.");
        }
      }
    }
  };

  // Define a threshold date for the new marker
  const thresholdDate = new Date();
  thresholdDate.setDate(thresholdDate.getDate() - 7);

  return (
    <div className="gallery">
      <h1>Gallery</h1>
      {isAdmin && <Link to="/upload-images" className="upload-link">Upload New Image</Link>}
      <div className="filter-controls">
        <label>
          <input 
            type="checkbox" 
            checked={showBlogImages} 
            onChange={() => setShowBlogImages(!showBlogImages)} 
          />
          Include Blog Images
        </label>
        <select 
          value={sortOrder} 
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value="newest">Sort by Newest</option>
          <option value="oldest">Sort by Oldest</option>
        </select>
      </div>

      {loading ? (
        <div className="loading-screen">
          <p>Loading images...</p>
          {/* Optionally include a spinner */}
        </div>
      ) : (
        <div className="image-grid">
          {allImages.map((image, index) => (
            <div key={index} className="image-item" onClick={() => openModal(image)}>
              <img src={image.url} alt={`Gallery ${index}`} />
              {image.createdAt > thresholdDate && <span className="new-tag">New</span>}
            </div>
          ))}
        </div>
      )}

      {selectedImage && (
        <div className="image-modal" onClick={closeModal}>
          <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage.url} alt="Full Size" />
            {selectedImage.type === 'blog' && (
              <p><Link to={`/blog/${selectedImage.postId}`} className="image-link">This image is from a blog post. </Link></p>
            )}
            {selectedImage.description && (
              <p>{selectedImage.description}</p>
            )}
            <p>{selectedImage.createdAt.toLocaleString()}</p>
            {selectedImage.type === 'gallery' && (
              <>
                <p>
                  {selectedImage.longitude ? (
                    <Link
                      to={`/map?place=${selectedImage.place}&lng=${selectedImage.longitude}&lat=${selectedImage.latitude}`}
                      className="place-link"
                    >
                      {selectedImage.place}
                    </Link>
                  ) : (
                    selectedImage.place
                  )}
                </p>
                <div className="horizontal-line"></div>
                <CommentsSection 
                  itemId={selectedImage.id} 
                  itemType="gallery"
                />
              </>
            )}
            {isAdmin && selectedImage.type === 'gallery' && (
              <>
                <div className="horizontal-line"></div>
                <button className="delete-button" onClick={handleDeleteImage}>Delete Image</button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
