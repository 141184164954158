import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import Home from "./pages/home/Home"
import Blog from "./pages/blog/Blog"
import Gallery from "./pages/gallery/Gallery"
import Map from "./pages/map/Map"
import LogIn from "./pages/login/LogIn"
import SignUp from "./pages/signup/SignUp"
import Profile from "./pages/profile/Profile"
import NavBar from "./components/navbar/NavBar"
import CreateBlog from "./pages/createblog/CreateBlog"
import BlogPage from "./pages/blogpage/Blogpage"
import UploadImages from "./pages/uploadimages/UploadImages"
import { AuthProvider } from "./contexts/AuthContext"
import { StorageProvider } from "./contexts/StorageContext"
import { DatabaseProvider } from "./contexts/DatabaseContext"
import ForgotPassword from "./pages/forgotpassword/ForgotPassword"
import PrivateRoute from "./components/privateroute/PrivateRoute"
import "./app.css"
/*import { SpeedInsights } from "@vercel/speed-insights/next"
import { Analytics } from "@vercel/analytics/react"
*/

export default function App() {
  return (
    <Router>
      <>
        <AuthProvider>
          <StorageProvider>
            <DatabaseProvider>
              <NavBar />
              <div className="padding">
                <Routes>
                  <Route exact path="/" element={<Home />}></Route>
                  <Route path="/home" element={<Home />}></Route>
                  <Route path="/blog" element={<Blog />}></Route>
                  <Route path="/gallery" element={<Gallery />}></Route>
                  <Route path="/map" element={<Map />}></Route>
                  <Route path="/login" element={<LogIn />}></Route>
                  <Route path="/signup" element={<SignUp />}></Route>
                  <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                  <Route exact path="/profile" element={<PrivateRoute/>}>
                    <Route path="/profile" element={<Profile />}></Route>
                  </Route>
                  <Route exact path="/create-blog" element={<PrivateRoute/>}>
                    <Route path="/create-blog" element={<CreateBlog />}></Route>
                  </Route>
                  <Route exact path="/upload-images" element={<PrivateRoute/>}>
                    <Route path="/upload-images" element={<UploadImages />}></Route>
                  </Route>
                  <Route path="/blog/:id" element={<BlogPage />}></Route>
                </Routes>
              </div>
            </DatabaseProvider>
          </StorageProvider>
        </AuthProvider>
      </>
    </Router>
  );
}