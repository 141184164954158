import "./navbar.css";
import { useState, useRef, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { ReactComponent as Hamburger } from "../../assets/icons/burger.svg";
import { ReactComponent as Logo } from "../../assets/icons/japan.svg";

export default function NavBar() {
  const [showNavbar, setShowNavbar] = useState(false);
  const [error, setError] = useState("");
  const { currentUser, logout, photoURL } = useAuth();
  const navigate = useNavigate();
  
  // Create a ref for the navbar elements
  const navRef = useRef(null);
  const menuIconRef = useRef(null); // Ref for the hamburger icon

  // Toggle the visibility of the navbar hamburger menu
  const handleShowNavbar = () => {
    setShowNavbar((prevState) => !prevState);
  };

  // Close the navbar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside both the navbar and the hamburger icon
      if (
        navRef.current && 
        !navRef.current.contains(event.target) && 
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target)
      ) {
        setShowNavbar(false); // Close the navbar if clicked outside
      }
    };

    // Use 'mousedown' for better click handling
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle user logout process
  async function handleLogout() {
    setError(""); // Clear previous errors
    try {
      await logout(); // Attempt to log out the user
      navigate("/");
    } catch {
      setError("Failed Logging Out");
      console.log(error);
    }
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <Logo className="logo-svg" />
          </Link>
          <NavLink to="/" className="logo-link">
            <div className="logo-text">
              Linus' Exchange<br />Journey
            </div>
          </NavLink>
        </div>

        {/* Hamburger Icon */}
        <div ref={menuIconRef} className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>

        {/* Navbar elements */}
        <div ref={navRef} className={`nav-elements ${showNavbar && "active"}`}>
          <ul className="navbarLeft">
            <li className="navListItem">
              <NavLink to="/">Home</NavLink>
            </li>
            <li className="navListItem">
              <NavLink to="/blog">Blogs</NavLink>
            </li>
            <li className="navListItem">
              <NavLink to="/gallery">Gallery</NavLink>
            </li>
            <li className="navListItem">
              <NavLink to="/map">Map</NavLink>
            </li>
            {currentUser ? (
              <>
                <li className="navListItem">
                  <NavLink to="/profile">Profile</NavLink>
                </li>
                <li className="navListItem">
                  <button onClick={handleLogout} className="navButton">Log Out</button>
                </li>
                <li className="navListItem">
                  <Link to="/profile" className="profile-link">
                    <img className="ProfilePic" src={photoURL} alt="" />
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="navListItem">
                  <NavLink to="/login">Log in</NavLink>
                </li>
                <li className="navListItem">
                  <NavLink to="/signup">Sign up</NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
