import React, { useState } from 'react';
import "./blogposts.css";
import BlogPost from '../blogpost/BlogPost';
import { useDatabase } from "../../contexts/DatabaseContext";
import DOMPurify from 'dompurify';

export default function BlogPosts() {
  const { blogs } = useDatabase();
  const [startIndex, setStartIndex] = useState(0);
  const blogsPerPage = 2; // Number of blogs to display per "page"
  const maxBlogsToShow = 10; // Maximum number of blogs to display in the carousel

  // Calculate the slice indices based on startIndex and blogsPerPage
  const start = startIndex;
  const end = Math.min(start + blogsPerPage, maxBlogsToShow);

  // Handler for moving to the previous page
  const moveToPrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - blogsPerPage);
    }
  };

  // Handler for moving to the next page
  const moveToNext = () => {
    if (startIndex + blogsPerPage < maxBlogsToShow) {
      setStartIndex(startIndex + blogsPerPage);
    }
  };

  return (
    <div className="blog-posts-carousel">
      <div className="blog-container">
        {blogs.slice(start, end).map(post => (
          <BlogPost
            key={post.id}
            title={post.data.title || "No Name"}
            place={post.data.place || "Unknown Location"}
            date={post.data.date || "2024/04/30"}
            timeStamp={post.data.time + " JST" || "8:30 JST"}
            postDesc={DOMPurify.sanitize(post.data.body, { ALLOWED_TAGS: [] }) || "This blog has no body"}
            prevImgSrc={post.data.photoURLs && post.data.photoURLs.length > 0
              ? post.data.photoURLs[0]
              : "https://www.infoplease.com/sites/infoplease.com/files/inline-images/fuji-mountain-and-cherry-blossoms-in-spring-japan.jpg_s%3D1024x1024%26w%3Dis%26k%3D20%26c%3D2L0VNpLOhWAyrAD6MDOz9n71ia1Du3Krp41gt0K8GIk%3D-min.jpg"}
            postId={post.id}
          />
        ))}
      </div>
      <div className="carousel-controls">
        <button
          className="carousel-control-prev"
          onClick={moveToPrevious}
          disabled={startIndex === 0}
        >
          Previous
        </button>
        <button
          className="carousel-control-next"
          onClick={moveToNext}
          disabled={startIndex + blogsPerPage >= maxBlogsToShow}
        >
          Next
        </button>
      </div>
    </div>
  );
}
