import React, { useRef, useState } from 'react'
import "../signup/signup.css"
import { useAuth } from "../../contexts/AuthContext"
import { Link } from "react-router-dom"
import "../login/login.css";

// TODO: Fix this function as it doesn't seem to work anymore
export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetpassword, checkEmailExists } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  // Handle submission of forgot password request
  async function handleSubmit(e) {
    e.preventDefault();
    setMessage("");
    setError("");
    setLoading(true);
  
    try {
      // Check if the email exists using Firebase Authentication
      const signInMethods = await checkEmailExists(emailRef.current.value);
      
      if (signInMethods.length === 0) {
        // If no sign-in methods are found, the email is not registered
        throw new Error("This email is not registered.");
      }
  
      // Proceed with sending the password reset email
      await resetpassword(emailRef.current.value);
      setMessage("Check your email for further instructions.");
    } catch (error) {
      // Firebase will return an error if the email doesn't exist or something else went wrong
      setError(error.message || "Failed to send password reset email.");
    } finally {
      setLoading(false);
    }
  }  
 
  return (
    <>
        <div className="login-card">
            <h2 className="login-title">Reset Password</h2>
            <form className="login-form" onSubmit={handleSubmit}>
                <div className="form-group" id="email">
                    <label>Email</label>
                    <input type="email" ref={emailRef} required className="form-control" />
                </div>
                <div className="button-container">
                    <button disabled={loading} type="submit" className="btn btn-primary login-button">Reset Password</button>
                </div>
            </form>
            <div className="error-container">
                {error && <div className="error-message"><h3>{error}</h3></div>}
                {message && <div className="success-message"><h3>{message}</h3></div>}
            </div>
        </div>
        <div className="forgot">
            <Link to="/login">Log In</Link>
        </div>
        <div className="signup">
            Need an account? <Link to="/signup">Sign Up</Link>
        </div>
    </>
    );
}
