import React, { useContext } from 'react';
import { storage } from "../firebase";
import { useAuth } from "./AuthContext"
import { getDownloadURL } from "firebase/storage";

const StorageContext = React.createContext();

// Custom hook to use the StorageContext
export function useStorage() {
    return useContext(StorageContext);
}

// Provider component for storage context
export function StorageProvider({ children }) {
    const { currentUser, updateProfilePic } = useAuth();

    // Function to resize an image to fit within maxWidth and maxHeight constraints, used for profile pictures
    const resizeImage = (file, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();
    
            reader.onload = (e) => {
                img.src = e.target.result;
            };
    
            reader.readAsDataURL(file);
    
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
    
                let width = img.width;
                let height = img.height;
    
                // Calculate new dimensions
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
    
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
    
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, file.type);
            };
    
            img.onerror = (err) => {
                reject(err);
            };
        });
    };
    
    // Function to delete the profile picture of a user
    async function deleteProfilePic(userId) {
        try {
            await storage.ref(`users/${userId}/profile.jpg`).delete();
        } catch (error) {
        }
    }
    
    //Function to upload a file to storage
    async function setProfilePic(file, setLoading) {
        try {
            // Resize the image first (to fit within 480x480 pixels)
            const resizedBlob = await resizeImage(file, 480, 480);
            const resizedFile = new File([resizedBlob], file.name, { type: file.type });

            // Delete existing profile picture
            await deleteProfilePic(currentUser.uid);

            // Get the reference to the storage location where you want to upload the file
            const fileRef = storage.ref(`users/${currentUser.uid}/profile.jpg`);
            setLoading(true);

            // Upload the resized file
            await fileRef.put(resizedFile);
            const photoURL = await getDownloadURL(fileRef);
            await updateProfilePic(currentUser, photoURL);

            // Update the state or perform any other actions after successful upload
            setLoading(false);
            alert("File uploaded successfully!");
        } catch (error) {
            console.error("Error uploading file:", error);
            setLoading(false);
            alert("Error uploading file:", error.message);
        }
    }

    // Context value to be provided
    const value = {
        setProfilePic,
        deleteProfilePic
    };

    return (
        <StorageContext.Provider value={value}>
            {children}
        </StorageContext.Provider>
    );
}