import React, { useState, useRef } from 'react';
import { useDatabase } from '../../contexts/DatabaseContext';
import { useLocation } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../../components/sidebar/sidebar.css';
import LocationPointer from '../../assets/icons/location-pointer.svg';
import BlogPointer from '../../assets/icons/blog-pointer.svg';
import RecommendationPointer from '../../assets/icons/recommendation-pointer.svg';
import UserRecommendationPointer from '../../assets/icons/user-recommendation-pointer.svg';
import VisitedPointer from '../../assets/icons/visited-pointer.svg';
import GalleryPointer from '../../assets/icons/gallery-pointer.svg';
import Sidebar from '../../components/sidebar/Sidebar';
import { ReactComponent as PlusIcon } from '../../assets/icons/sidebar-burger.svg';
import './map.css';

function MyMap() {
  // Extract necessary functions and state from context
  const { markers, addMarker, galleryMarkers, blogMarkers } = useDatabase();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [newMarker, setNewMarker] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [type, setType] = useState(null);
  const [filters, setFilters] = useState({ 
    showGallery: true, 
    showBlog: true, 
    showRecommended: true, 
    showVisited: true, 
    showUserRecommendations: true 
  });
  const mapRef = useRef();
  const location = useLocation();
  const { currentUser } = useAuth();

  // Handle map click and open the sidebar accordingly
  const handleMapClick = (event) => {
    // Prevent setting a new marker if there's already a marker there
    if (selectedMarker || ["img", "blog", "marker"].includes(type)) return;

    // Open sidebar without adding a marker if user is not logged in
    if (!currentUser) {
      setIsSidebarOpen(true);
      setType(null);
      return;
    }

    const { lng, lat } = event.lngLat;
    setNewMarker({ longitude: lng, latitude: lat });
    setType("recommendation");
    setIsSidebarOpen(true);
  };

  // Handle form submission for a new marker
  const handleFormSubmit = async (formData) => {
    const newMarkerData = {
      ...formData,
      images: [formData.image],
      tags: formData.tags.split(',').map(tag => tag.trim()),
      createdAt: new Date().toISOString(),
      longitude: newMarker.longitude,
      latitude: newMarker.latitude,
    };
    await addMarker(newMarkerData);
    setNewMarker(null);
    setIsSidebarOpen(false);
  };

  // Handle clicks on different types of markers
  const handleMarkerClick = (marker) => {
    setNewMarker(null);
    setSelectedMarker(marker);
    setIsSidebarOpen(true);
    setType("marker");
  };

  const handleImageMarkerClick = (image) => {
    setNewMarker(null);
    setSelectedMarker(image);
    setIsSidebarOpen(true);
    setType("img");
  };

  const handleBlogMarkerClick = (blog) => {
    setNewMarker(null);
    setSelectedMarker(blog);
    setIsSidebarOpen(true);
    setType("blog");
  };

  // Sidebar handling functions
  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setNewMarker(null);

    setTimeout(() => {
      setSelectedMarker(null);
      setType(null);
    }, 300); // Delay for animation to finish
  };

  const openSidebar = () => {
    setSelectedMarker(null);
    setNewMarker(null);
    setIsSidebarOpen(true);
  };

  const toggleSidebar = () => {
    if (isSidebarOpen) {
      closeSidebar()
    } else {
      openSidebar()
    }
  };

  // Handle and update marker filters
  const handleFilterChange = (newFilters) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters
    }));
  };

  return (
    <div className="map-container">
      <Sidebar
        selectedMarker={selectedMarker}
        onAddMarker={handleFormSubmit}
        isOpen={isSidebarOpen}
        type={type}
        onFilterChange={handleFilterChange}
        closeSidebar={closeSidebar}
      />
      <button className="plus-icon" onClick={toggleSidebar}>
        <PlusIcon />
      </button>
      <Map
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        initialViewState={{
          longitude: 140.869415,
          latitude: 38.268223,
          zoom: 12,
        }}
        style={{ width: "100vw", height: `calc(100vh - 85px)` }}
        mapStyle="mapbox://styles/limme2001/clyw4ollp006c01r29iep1epn"
        onClick={handleMapClick}
        onLoad={() => {
          const params = new URLSearchParams(location.search);
          const place = params.get('place');
          const lng = parseFloat(params.get('lng'));
          const lat = parseFloat(params.get('lat'));
        
          if (place && !isNaN(lng) && !isNaN(lat)) {
            // Search in galleryMarkers first
            let foundMarker = galleryMarkers.find((img) => {
              const imgLng = parseFloat(img.longitude);
              const imgLat = parseFloat(img.latitude);
              return (
                img.place === place &&
                !isNaN(imgLng) && !isNaN(imgLat) &&
                imgLng.toFixed(6) === lng.toFixed(6) &&
                imgLat.toFixed(6) === lat.toFixed(6)
              );
            });
        
            // If not found in gallery markers, search in blog markers
            if (!foundMarker) {
              foundMarker = blogMarkers.find((blog) => {
                const blogLng = parseFloat(blog.data.longitude);
                const blogLat = parseFloat(blog.data.latitude);
                return (
                  blog.data.place === place &&
                  !isNaN(blogLng) && !isNaN(blogLat) &&
                  blogLng.toFixed(6) === lng.toFixed(6) &&
                  blogLat.toFixed(6) === lat.toFixed(6)
                );
              });
        
              if (foundMarker) {
                setType('blog'); 
              }
            } else {
              setType('img'); 
            }
        
            if (foundMarker) {
              setSelectedMarker(foundMarker);
              setIsSidebarOpen(true);
              if (mapRef.current) {
                mapRef.current.flyTo({
                  center: [lng, lat],
                  zoom: 15,
                  essential: true,
                });
              }
            }
          }
        }}
      >
        {markers
          .filter(marker => {
            // Check if a user is logged in
            const userRecommendations = currentUser ? (marker.uid === currentUser.uid && marker.type === 'recommendation') : false;

            return (
              (filters.showRecommended && marker.type === 'recommendation') ||
              (filters.showVisited && marker.type === 'visited') ||
              (filters.showUserRecommendations && userRecommendations)
            );
          })
          .map(marker => {
            // Determine the appropriate svg based on marker.type
            let markerSrc;
            if (marker.type === 'recommendation' && currentUser && marker.uid === currentUser.uid) {
              markerSrc = UserRecommendationPointer;
            } else {
              switch (marker.type) {
                case 'visited':
                  markerSrc = VisitedPointer;
                  break;
                case 'recommendation':
                  markerSrc = RecommendationPointer;
                  break;
                case 'user-recommendation':
                  markerSrc = UserRecommendationPointer;
                  break;
                default:
                  markerSrc = LocationPointer; // Default image
              }
            }

            return (
              <Marker
                key={marker.id}
                longitude={marker.longitude}
                latitude={marker.latitude}
                anchor="bottom"
              >
                <img
                  src={markerSrc}
                  style={{ width: '40px', height: '40px', cursor: 'pointer' }}
                  onClick={() => handleMarkerClick(marker)}
                  alt="marker"
                />
              </Marker>
            );
          })}

        {filters.showGallery && galleryMarkers.map(image => (
          <Marker
            key={image.id}
            longitude={image.longitude}
            latitude={image.latitude}
            anchor="bottom"
          >
            <img
              src={GalleryPointer}
              style={{ width: '40px', height: '40px', cursor: 'pointer' }}
              onClick={() => handleImageMarkerClick(image)}
              alt="gallery marker"
            />
          </Marker>
        ))}

        {filters.showBlog && blogMarkers.map(blog => (
          <Marker
            key={blog.id}
            longitude={blog.data.longitude}
            latitude={blog.data.latitude}
            anchor="bottom"
          >
            <img
              src={BlogPointer}
              style={{ width: '40px', height: '40px', cursor: 'pointer' }}
              onClick={() => handleBlogMarkerClick(blog)}
              alt="blog marker"
            />
          </Marker>
        ))}

        {newMarker && (
          <Marker
            longitude={newMarker.longitude}
            latitude={newMarker.latitude}
            anchor="bottom"
          >
            <img
              src={LocationPointer}
              style={{ width: '40px', height: '40px', cursor: 'pointer' }}
              alt="new marker"
            />
          </Marker>
        )}
      </Map>
    </div>
  );
}

export default MyMap;
