import React, { useRef, useState } from 'react';
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle login attempt
  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/");
    } catch {
      setError('Failed to log in');
    }
    setLoading(false);
  }

  return (
    <>
      <div className="login-card">
        <h1 className="login-title">Log In</h1>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-group" id="email">
            <label>Email</label>
            <input type="email" ref={emailRef} required className="form-control" />
          </div>
          <div className="form-group" id="password">
            <label>Password</label>
            <input type="password" ref={passwordRef} required className="form-control" />
          </div>
          <div className="button-container">
            <button disabled={loading} type="submit" className="btn btn-primary login-button">Log In</button>
          </div>
        </form>
        <div className="error-container">
            {error && <div className="error-message"><h3>{error}</h3></div>}
        </div>
      </div>
      <div className="forgot">
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
      <div className="signup">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </>
  );
}
