import "./header.css"

export default function Header() {
  return (
    <div className = "header">
        {/*<div className = "headerTitles">
            <span className="headerTitleSm">Welcome to my</span>
            <span className="headerTitleLg">Exchange Site</span>
        </div>*/}
        <img className="headerImg" src="https://www.state.gov/wp-content/uploads/2019/04/Japan-2107x1406.jpg" alt=""/>
    </div>
  )
}
