import React, { useContext, useState, useEffect } from 'react';
import { auth } from "../firebase";

const AuthContext = React.createContext();

// Custom hook to use the AuthContext
export function useAuth() {
  return useContext(AuthContext);
}

// Provider component for authentication context
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png")

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
    /* ADD BACK IF YOU WANT TO VERIFY USERS
    .then(userCredential => {
        const user = userCredential.user;
        // Send email verification
        user.sendEmailVerification();
        return userCredential; // Return userCredential to be used elsewhere
      });
    */
  }  

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  // Send password reset email
  function resetpassword(email) {
    return auth.sendPasswordResetEmail(email).catch((error) => {
      if (error.code === 'auth/user-not-found') {
        throw new Error("No user found with this email.");
      } else if (error.code === 'auth/invalid-email') {
        throw new Error("The email address is badly formatted.");
      } else {
        throw new Error("Failed to send reset email. Please try again.");
      }
    });
  }  
  
  // Check if email exists in the authentication system, ONLY WORKS WHEN EMAIL ENUMERATION PROTECTION IS OFF
  function checkEmailExists(email) {
    return auth.fetchSignInMethodsForEmail(email);
  }

  async function updateProfilePic(user, photoURL){
    await user.updateProfile({photoURL})
    setPhotoURL(photoURL)
  }
  
  async function updateUsername(user, username) {
    await user.updateProfile({ displayName: username });
    setCurrentUser({ ...user, displayName: username });
  }  

  // Effect to manage authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        if (user.photoURL) {
          setPhotoURL(user.photoURL);
        } else if (!photoURL) {
          // Set photoURL to the default value only if it's not already set
          setPhotoURL("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png");
        }
        setCurrentUser(user);
        setLoading(false);
      } else {
        // If user is signed out, reset photoURL to default value
        setPhotoURL("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png");
        setCurrentUser(null);
        setLoading(false);
      }
    });
    // Clean up subscription on unmount
    return unsubscribe;
  }, [photoURL]);

  // Value to be provided by the context
  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetpassword,
    checkEmailExists,
    photoURL,
    updateProfilePic,
    updateUsername,
  };

  // Provide context value and manage loading state
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}