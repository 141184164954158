import React from 'react'
import "./blogpost.css"
import { Link } from 'react-router-dom';

export default function BlogPost(blogData) {
  const { title, place, date, timeStamp, postDesc, prevImgSrc, postId } = blogData

  return (
    <div className="blogpost">
        <Link to={`/blog/${postId}`} className="blog-post-link">
        <img className="prevImg" src={prevImgSrc} alt="https://www.infoplease.com/sites/infoplease.com/files/inline-images/fuji-mountain-and-cherry-blossoms-in-spring-japan.jpg_s%3D1024x1024%26w%3Dis%26k%3D20%26c%3D2L0VNpLOhWAyrAD6MDOz9n71ia1Du3Krp41gt0K8GIk%3D-min.jpg">
        </img>
        <div className="postInfo">
            <span className="title">{title}</span>
            <hr></hr>
            <span className="place">
                {place}
            </span>
            <div className="dateContainer">
                <span className="date">{date}</span>
                <span>&nbsp;</span>
                <span className="timeStamp">{timeStamp}</span>
            </div>
        </div>
        <p className="postDesc">
            {postDesc}
        </p>
        </Link>
    </div>
  )
}
