import "./home.css"
import Header from "../../components/header/Header"
import BlogPosts from "../../components/blogposts/BlogPosts"
import Footer from "../../components/footer/Footer"
import { Link } from "react-router-dom"

export default function Home() {
  return (
    <>
      <Header/>
      <div className = "home">
          <div className = "about">
              <h1>
                Welcome!
              </h1>
              <p>
                This is my personal website, a digital journal dedicated to documenting my exchange year at Tohoku University! I am currently pursuing my studies in applied physics and electrical engineering and this site serves as my online diary and showcase for my time abroad, providing a glimpse into my academic and personal adventures. The site is currently in beta and works the best on PC at the moment but I will continue to update it. Thank you for visiting, and feel free to join me on this exciting journey! 
              </p>
              <div className="horizontal-line"></div>
          </div>
          <h1 className = "homepage-titles">
            Blogs
          </h1>
          <div>
            <BlogPosts></BlogPosts>
          </div>
          <p className="carouselExcuse">Yes this carousel is horrible and will be updated. It is self-made in pure css x_x</p>
          <p className="carouselExcuse">
            <Link to="/blog">Go to all blogs instead</Link>
          </p>
          <div className="horizontal-line"></div>
          <h1 className = "homepage-titles">
            Gallery
          </h1>
          <p className="carouselExcuse">
              Didn't wan't to put you through the pain of using the carousel again...
          </p>
          <p className="carouselExcuse"><Link to="/gallery">Go to gallery</Link></p>
          <div className="spacer"></div> 
      </div>
      <Footer />
    </>
  )
}