import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { useDatabase } from "../../contexts/DatabaseContext";
import styles from "./blog.module.css"; 
import DOMPurify from 'dompurify';

export default function Blog() {
  const { blogs, admins } = useDatabase();
  const { currentUser } = useAuth();
  const isAdmin = currentUser && admins.includes(currentUser.uid);
  const [sortOrder, setSortOrder] = useState('newest');
  const [sortedBlogs, setSortedBlogs] = useState([]);
  const stockImage = "https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Flag_of_Japan.svg/1200px-Flag_of_Japan.svg.png";
  const thresholdDate = new Date();
  thresholdDate.setDate(thresholdDate.getDate() - 7);
  

  useEffect(() => {
    // Sort blogs based on the selected order
    const sorted = [...blogs].sort((a, b) => {
      const dateA = new Date(a.data.date); // Convert blog date to Date object
      const dateB = new Date(b.data.date);

      return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
    });

    setSortedBlogs(sorted);
  }, [blogs, sortOrder]);

  return (
    <div className={styles['blog-container']}>
      <div className={styles['header-controls']}>
        <h1>All Blogs</h1>
        {isAdmin && <Link to="/create-blog" className={styles['upload-link']}>Create New Blog</Link>}
        <div className={styles['filter-controls']}>
          <select 
            value={sortOrder} 
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="newest">Sort by Newest</option>
            <option value="oldest">Sort by Oldest</option>
          </select>
        </div>
      </div>
      <div className={styles['blogs-grid']}>
        {sortedBlogs.map(blog => (
          <div key={blog.id} className={styles['blog-post-wrapper']}>
            <Link to={`/blog/${blog.id}`} className={styles['blog-post-link']}>
              <div className={styles.blogpost}>
                <img
                  className={styles.prevImg}
                  src={blog.data.photoURLs && blog.data.photoURLs.length > 0 ? blog.data.photoURLs : stockImage}
                  alt="Preview"
                />
                <div className={styles.postInfo}>
                  <h2 className={styles.title}>{blog.data.title || "No Title"}</h2>
                  <p className={styles.place}>{blog.data.place || "No Location"}</p>
                  <div className={styles.dateContainer}>
                    <p className={styles.date}>{blog.data.date} {blog.data.time + " JST"}</p>
                  </div>
                  <p className={styles.postDesc}>{DOMPurify.sanitize(blog.data.body, { ALLOWED_TAGS: [] }) || "No Description"}</p>
                </div>
              </div>
            </Link>
            {blog.data.createdAt.toDate() > thresholdDate && (
              <span className={styles.newtag}>New</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}