import React, { useContext, useState, useEffect } from 'react';
import { firestore, storage, FieldValue } from "../firebase";
import { getDownloadURL } from "firebase/storage";

const DatabaseContext = React.createContext();

// Custom hook to use the DatabaseContext
export function useDatabase() {
    return useContext(DatabaseContext);
}

// Provider component for database context
export function DatabaseProvider({ children }) {
    const [blogs, setBlogs] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const [galleryMarkers, setGalleryMarkers] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [blogMarkers, setBlogMarkers] = useState([]);
    
    // Mapping item types to Firestore collections, used for commentsection
    const COLLECTION_MAP = {
        blog: 'blogs',
        gallery: 'gallery',
        marker: 'markers'
    };

    useEffect(() => {
        // Set up Firestore listeners and fetch initial data

        // Listen to changes in the 'blogs' collection
        const unsubscribeBlogs = firestore.collection('blogs')
            .orderBy('data.createdAt', 'desc')
            .onSnapshot(snapshot => {
                const blogsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setBlogs(blogsData);
                
                // Filter blogs with coordinates for markers used by map
                const filteredBlogMarkers = blogsData.filter(blog => blog.data?.latitude && blog.data?.longitude);
                setBlogMarkers(filteredBlogMarkers);
            });

        const fetchAdmins = async () => {
            const doc = await firestore.collection('admin').doc('5xLNR2CIReO9trqWKgcw').get();
            if (doc.exists) {
                const adminData = doc.data();
                if (adminData && adminData.admins) {
                    setAdmins(adminData.admins);
                }
            }
        };

        // Listen to changes in the 'gallery' collection
        const fetchGalleryImages = () => {
            const unsubscribeGallery = firestore.collection('gallery')
                .orderBy('createdAt', 'desc')
                .onSnapshot(snapshot => {
                    const galleryData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setGalleryImages(galleryData);
                    setGalleryMarkers(galleryData.filter(image => image.longitude));
                });
            return unsubscribeGallery;
        };

        // Listen to changes in the 'markers' collection
        const fetchMarkers = () => {
            const unsubscribeMarkers = firestore.collection('markers')
                .onSnapshot(snapshot => {
                    const markersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setMarkers(markersData);
                });
            return unsubscribeMarkers;
        };

        fetchAdmins();
        const unsubscribeGallery = fetchGalleryImages();
        const unsubscribeMarkers = fetchMarkers();

        // Clean up Firestore listeners on component unmount
        return () => {
            unsubscribeBlogs();
            unsubscribeGallery();
            unsubscribeMarkers();
        };
    }, []); // Run only on mount

    // Add a new blog entry to Firestore
    async function addBlog(blogData){
        if (blogData.longitude == null || blogData.latitude == null) 
        {
            delete blogData.longitude;
            delete blogData.latitude;
        }
        
        await firestore.collection('blogs').add(blogData);
    }

    // Update an existing blog entry, not in use
    async function editBlog(blogId, updatedData){
        await firestore.collection('blogs').doc(blogId).update(updatedData);
    }

    // Delete a blog entry, the commentsection and associated images
    async function deleteBlog(blogId, photoURLs) {
        try {
          // Delete images from Firebase Storage
          for (const url of photoURLs) {
            const fileName = getFileNameFromUrl(url);
            const imageRef = storage.ref().child(`${fileName}`);
            await imageRef.delete();
          }
          
          // Delete the blog document from Firestore
          await firestore.collection('blogs').doc(blogId).delete();
        } catch (error) {
          console.error("Error deleting blog:", error);
          throw error;
        }
    }

    // Upload an image and add its data to Firestore
    async function uploadImage(file, imageData = {}) {
        try {
            const fileRef = storage.ref().child(`gallery_images/${Date.now()}_${file.name}`);
            await fileRef.put(file);
            const fileURL = await getDownloadURL(fileRef);
            const createdAt = new Date();

            const data = {
                url: fileURL,
                createdAt,
                ...imageData
            };

            // Remove coordinates if they are null to avoid marker problems
            if (imageData.longitude == null || imageData.latitude == null) {
                delete data.longitude;
                delete data.latitude;
            }

            await firestore.collection('gallery').add(data);
            return fileURL;
        } catch (error) {
            console.error("Error uploading image:", error);
            throw error;
        }
    }

    // Add a new marker to Firestore
    async function addMarker(markerData) {
        await firestore.collection('markers').add(markerData);
    }

    // Extract file name from URL
    function getFileNameFromUrl(url) {
        const urlParts = new URL(url).pathname.split('/');
        const encodedFileName = urlParts.pop();
        return decodeURIComponent(encodedFileName);
    }

    // Delete an image from gallery and its storage
    async function deleteImageFromGallery(imageId) {
        try {            
            // Retrieve the document to get the URL
            const doc = await firestore.collection('gallery').doc(imageId).get();
            if (!doc.exists) {
                throw new Error("Document not found");
            }
            const imageData = doc.data();
            const imageURL = imageData.url;
    
            // Extract file name from the URL
            const fileName = getFileNameFromUrl(imageURL);

            // Delete the document from Firestore
            await firestore.collection('gallery').doc(imageId).delete();
            
            // Delete the file from Firebase Storage
            const imageRef = storage.ref().child(`${fileName}`);
            await imageRef.delete();
        } catch (error) {
            console.error("Error deleting image:", error);
            throw error;
        }
    }

    // Save a username associated with a user ID
    async function saveUsername(userId, username) {
        const usernameRef = firestore.collection('usernames').doc(userId);
        await usernameRef.set({ username });
    }

    // Check if a username already exists
    async function checkUsernameExists(username) {
        const usernameRef = firestore.collection('usernames');
        const query = await usernameRef.where('username', '==', username).get();
        return !query.empty;
    }

    // Commentsection
    // --------------
    // Get username associated with a user ID
    async function getUserName(userId) {
        const userDoc = await firestore.collection('usernames').doc(userId).get();
        if (userDoc.exists) {
            return userDoc.data().username;
        }
    }

    // Get user profile picture URL
    async function getUserProfilePic(userId) {
        try {
            const profilePicRef = storage.ref(`users/${userId}/profile.jpg`);
            const url = await profilePicRef.getDownloadURL();
            return url; 
        } catch (error) {
            // If the file doesn't exist or another error occurs, return the default profile picture
            console.error("Error fetching user profile picture:", error);
            return "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
        }
    }

    // Get comments for a specific item and type
    async function getComments(itemId, itemType) {
        // Determine the collection based on itemType
        const collection = COLLECTION_MAP[itemType];
    
        // Fetch comments from the appropriate collection
        const commentsSnapshot = await firestore.collection(collection).doc(itemId).collection('comments').orderBy('createdAt').get();
        const comments = await Promise.all(commentsSnapshot.docs.map(async (doc) => {
            const commentData = { id: doc.id, ...doc.data() };
    
            // Fetch user details for the comment
            const username = await getUserName(commentData.userId);
            const profilePic = await getUserProfilePic(commentData.userId);
    
            // Fetch replies with user details
            const replies = await Promise.all((commentData.replies || []).map(async (reply) => {
                const replyUserName = await getUserName(reply.userId);
                const replyProfilePic = await getUserProfilePic(reply.userId);
                return {
                    ...reply,
                    username: replyUserName,
                    userProfilePic: replyProfilePic
                };
            }));
    
            return {
                ...commentData,
                username,
                userProfilePic: profilePic,
                replies
            };
        }));
        return comments;
    }
    
    // Add a comment to an item
    async function addComment(itemId, text, userId, itemType) {
        // Determine the collection based on itemType
        const collection = COLLECTION_MAP[itemType];
    
        const commentData = {
            text,
            userId,
            createdAt: new Date(),
            replies: []
        };
        await firestore.collection(collection).doc(itemId).collection('comments').add(commentData);
    }

    // Add a reply to a specific comment
    async function addReply(itemId, commentId, text, userId, itemType) {
        // Determine the collection based on itemType
        const collection = COLLECTION_MAP[itemType];
    
        const replyData = {
            text,
            userId,
            createdAt: new Date()
        };
    
        try {
            await firestore.collection(collection).doc(itemId).collection('comments').doc(commentId).update({
                replies: FieldValue.arrayUnion(replyData) // Use FieldValue from Firestore
            });
        } catch (error) {
            console.error("Error adding reply:", error);
            throw error;
        }
    }

    async function deleteMarker(markerID) {
        try {          
          // Delete the marker from Firestore
          await firestore.collection('markers').doc(markerID).delete();
        } catch (error) {
          console.error("Error deleting marker:", error);
          throw error;
        }
    }

    // Value to be provided by the context
    const value = {
        blogs,
        admins,
        galleryImages,
        galleryMarkers,
        markers,
        blogMarkers,
        addBlog,
        editBlog,
        deleteBlog,
        uploadImage,
        addMarker,
        deleteImageFromGallery,
        saveUsername,
        checkUsernameExists,
        getComments,
        addComment,
        addReply,
        getUserName,
        getUserProfilePic,
        deleteMarker
    };

    return (
        <DatabaseContext.Provider value={value}>
            {children}
        </DatabaseContext.Provider>
    );
}