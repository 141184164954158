import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDatabase } from "../../contexts/DatabaseContext";
import { useAuth } from "../../contexts/AuthContext";
import CommentsSection from "../../components/commentsection/Commentsection";
import "./blogpage.css";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

export default function BlogPage() {
  const { id } = useParams(); // Extracts blog ID from URL params
  const { blogs, admins, deleteBlog } = useDatabase();
  const blog = blogs.find(blog => blog.id === id); // Finds the blog by ID
  const { currentUser } = useAuth();
  const isAdmin = currentUser && admins.includes(currentUser.uid);
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top only on mount
    const anchor = document.getElementById('blogPageTop');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'auto' });
    }
  }, []);

  // Handler for deleting the blog
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      await deleteBlog(id, blog.data.photoURLs); // Deletes blog, comments and associated photos
      navigate('/blog');  // Redirect to homepage after deletion
    }
  };

  // If blog not found, display a message
  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className="blogpage">
      <div id="blogPageTop" style={{ position: 'absolute', top: 0 }} />
      <h1>{blog.data.title || "No Name"}</h1>
      <p>{blog.data.date || "2024/04/30"} {blog.data.time + " JST" || "8:30 JST"}</p>
      <p>
        {blog.data.longitude ? (
          <Link
            to={`/map?place=${blog.data.place}&lng=${blog.data.longitude}&lat=${blog.data.latitude}`}
            className="place-link"
          >
            {blog.data.place}
          </Link>
        ) : (
          blog.data.place
        )}
      </p>
      {/* Image gallery */}
      {blog.data.photoURLs && (
        <div className="blogpage-images">
          {blog.data.photoURLs.map((url, index) => (
            <img key={index} src={url} alt=""/>
          ))}
        </div>
      )}
      <p className="blogbody">{parse(blog.data.body) || "This blog has no body"}</p>
      {isAdmin && <button onClick={handleDelete} className="delete-button">Delete Blog</button>}
      
      <div className="horizontal-line"></div>
      <CommentsSection itemId={id} itemType='blog'/>
    </div>
  );
}
