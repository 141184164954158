import React, { useState, useEffect } from 'react';
import { useDatabase } from "../../contexts/DatabaseContext";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from 'react-router-dom';
import "./commentsection.css";

const CommentsSection = ({ itemId, itemType }) => {
  const { getComments, addComment, addReply } = useDatabase();
  const { currentUser } = useAuth();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [replyText, setReplyText] = useState('');
  const [replyingTo, setReplyingTo] = useState(null);

  // Fetch comments when component mounts or itemId/itemType changes
  useEffect(() => {
    const fetchComments = async () => {
      const fetchedComments = await getComments(itemId, itemType);
      setComments(fetchedComments);
    };
    fetchComments();
  }, [itemId, itemType, getComments]);

  // Handle posting a new comment
  const handlePostComment = async () => {
    if (newComment.trim().length < 3) {
      alert('Comment must be at least 3 characters long.');
      return;
    }
    if (newComment.trim() === '') return;
    if (!currentUser) return; // Ensure user is logged in
    await addComment(itemId, newComment, currentUser.uid, itemType);
    setNewComment('');
    const updatedComments = await getComments(itemId, itemType);
    setComments(updatedComments);
  };

  // Handle posting a reply to a comment
  const handlePostReply = async (commentId) => {
    if (replyText.trim().length < 3) {
      alert('Reply must be at least 3 characters long.');
      return;
    }
    if (replyText.trim() === '') return;
    if (!currentUser) return; // Ensure user is logged in
    await addReply(itemId, commentId, replyText, currentUser.uid, itemType);
    setReplyText('');
    setReplyingTo(null);
    const updatedComments = await getComments(itemId, itemType);
    setComments(updatedComments);
  };

  return (
    <div className="comments-section">
      <h3>Comments</h3>
      {currentUser ? (
        <>
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Write a comment..."
            className=""
          />
          <button onClick={handlePostComment}>Post Comment</button>
        </>
      ) : (
        <p className="login-prompt"><Link to="/login">Log in</Link> to post comments</p>
      )}
      {comments.map(comment => (
        <div key={comment.id} className="comment">
          <div className="user-info">
            <img
              src={comment.userProfilePic || 'default-profile-pic.png'}
              alt="User profile"
            />
            <strong>{comment.username}</strong>
          </div>
          <p className="comment-text">{comment.text}</p>
          {currentUser && (
            <button onClick={() => setReplyingTo(replyingTo === comment.id ? null : comment.id)}>
              {replyingTo === comment.id ? 'Cancel' : 'Reply'}
            </button>
          )}
          {replyingTo === comment.id && currentUser && (
            <div className="reply-container">
              <textarea
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
                placeholder="Write a reply..."
              />
              <button onClick={() => handlePostReply(comment.id)} className="reply-button">Post Reply</button>
            </div>
          )}
          {comment.replies && comment.replies.map((reply, index) => (
            <div key={index} className="reply">
              <div className="user-info">
                <img
                  src={reply.userProfilePic || 'default-profile-pic.png'}
                  alt="User profile"
                />
                <strong>{reply.username}</strong>
              </div>
              <p>{reply.text}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CommentsSection;
