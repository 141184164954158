import React, { useState, useEffect} from 'react';
import './sidebar.css';
import { useAuth } from "../../contexts/AuthContext";
import { useDatabase } from "../../contexts/DatabaseContext";
import { Link } from 'react-router-dom';
import BlogPointer from '../../assets/icons/blog-pointer.svg';
import RecommendationPointer from '../../assets/icons/recommendation-pointer.svg';
import UserRecommendationPointer from '../../assets/icons/user-recommendation-pointer.svg';
import VisitedPointer from '../../assets/icons/visited-pointer.svg';
import GalleryPointer from '../../assets/icons/gallery-pointer.svg';
import CommentsSection from "../commentsection/Commentsection";
import parse from 'html-react-parser';

function Sidebar({ selectedMarker, onAddMarker, isOpen, type, onFilterChange, closeSidebar}) {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    image: '',
    tags: '',
    type: '',
  });
  const [showGallery, setShowGallery] = useState(true);
  const [showBlog, setShowBlog] = useState(true);
  const [showRecommended, setshowRecommended] = useState(true);
  const [showVisited, setshowVisited] = useState(true);
  const [showUserRecommendations, setshowUserRecommendations] = useState(true);
  const [formType, setFormType] = useState('recommendation');
  const [userRecommendations, setUserRecommendations] = useState({});
  const { admins, getUserName, getUserProfilePic, deleteMarker } = useDatabase();
  const { currentUser } = useAuth();
  const isAdmin = currentUser && admins.includes(currentUser.uid);

  // Fetch user data when selected marker changes
  useEffect(() => {
    if (selectedMarker && (selectedMarker.type === 'recommendation' || selectedMarker.type === 'visited') && selectedMarker.uid) {
      const fetchUserData = async () => {
        try {
          const username = await getUserName(selectedMarker.uid);
          const profilePic = await getUserProfilePic(selectedMarker.uid);
  
          setUserRecommendations(prev => ({
            ...prev,
            [selectedMarker.uid]: {
              username,
              profilePic
            }
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
  
      fetchUserData();
    }
  }, [selectedMarker, getUserName, getUserProfilePic]);
  
  // Handle input changes in the form when creating a recommendation
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle checkbox changes for filters
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'gallery') {
      setShowGallery(checked);
    } else if (name === 'blog') {
      setShowBlog(checked);
    } else if (name === 'recommendations') {
      setshowRecommended(checked);
    } else if (name === 'visited') {
      setshowVisited(checked);
    } else if (name === 'userRecommendations') {
      setshowUserRecommendations(checked);
    }
    onFilterChange({ showGallery: name === 'gallery' ? checked : showGallery, showBlog: name === 'blog' ? checked : showBlog, 
      showRecommended: name === 'recommendations' ? checked : showRecommended, showVisited: name === 'visited' ? checked : showVisited, 
      showUserRecommendations: name === 'userRecommendations' ? checked : showUserRecommendations });
  };

  // Handle form submission
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const dataToSubmit = {
      ...formData,
      uid: currentUser.uid,
      type: formType,
    };
    
    onAddMarker(dataToSubmit);
    
    // Reset form and form type
    setFormData({
      title: '',
      description: '',
      image: '',
      tags: '',
      type: '',
    });
    setFormType('recommendation');
  };
  
  // Set form type to 'visited' on button click
  const handleAddVisitedClick = () => {
    setFormType('visited');
  };

  // Handle deletion of the marker
  const handleDelete = async () => {
    if (selectedMarker && selectedMarker.id) {
      try {
        if (window.confirm("Are you sure you want to delete this marker?")) {
          await deleteMarker(selectedMarker.id);
          alert("Marker deleted successfully!");
          closeSidebar();
        }
      } catch (error) {
        console.error("Error deleting marker:", error);
      }
    }
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`} style={{ top: '85px' }}>
      <div className="sidebar-content">
        {selectedMarker ? (
          type === "marker" ? (
            <>
              <h1 className="sidebar-title">{selectedMarker.title}</h1>
              <h4
                className="type"
                style={{
                  color: selectedMarker.type === "recommendation" && currentUser && selectedMarker.uid === currentUser.uid
                    ? '#FF6347'
                    : selectedMarker.type === "recommendation"
                    ? '#8A2BE2'
                    : selectedMarker.type === "visited"
                    ? '#FFA500'
                    : '#000000'
                }}
              >
                {selectedMarker.type === "recommendation" && currentUser && selectedMarker.uid === currentUser.uid
                  ? "Your recommendation"
                  : selectedMarker.type === "recommendation"
                  ? "Recommendation"
                  : selectedMarker.type === "visited"
                  ? "Visited"
                  : "Unknown Type"}
              </h4>
              <div className="horizontal-line"></div>
              
              <div className="info-section">
                <p>{selectedMarker.description}</p>
              </div>
              {Array.isArray(selectedMarker?.images) && selectedMarker.images.filter(Boolean).length > 0 && (
                <div className="image-section">
                  {/* Render "Pictures:" only if there are valid images */}
                  <div>Pictures:</div>
                  <div className="images">
                    {selectedMarker.images.filter(Boolean).map((image, index) => (
                      <img 
                        key={index} 
                        src={image} 
                        alt={selectedMarker.title || 'Image'} 
                        onError={(e) => { e.target.style.display = 'none'; }} // Hide image on error
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className="horizontal-line"></div>
              {/* Commented out tag section as it's not used */}
              {/*<div className="tags-section">
                <label>Tags</label>
                <div className="tags">
                  {selectedMarker.tags.map((tag, index) => (
                    <span key={index}>#{tag}</span>
                  ))}
                </div>
              </div> */}
              <div>
                {(selectedMarker.type === 'recommendation' || selectedMarker.type === 'visited') && userRecommendations[selectedMarker.uid] && (
                  <div className="user-info">
                    <div className="recommended-by">
                      {selectedMarker.type === 'visited' ? 'Visited by:' : 'Recommended by:'}
                    </div>
                    <div className="profile-container">
                      <img
                        src={userRecommendations[selectedMarker.uid].profilePic || 'default-profile-pic.png'}
                        alt="User profile"
                        className="profile-pic"
                      />
                      <span className="username"><b>{userRecommendations[selectedMarker.uid].username}</b></span>
                    </div>
                  </div>
                )}
              </div>
              <div className="meta-section">
                <span className="date">Uploaded: {new Date(selectedMarker.createdAt).toLocaleString()}</span>
              </div>
              <div className="horizontal-line"></div>
              <CommentsSection itemId={selectedMarker.id} itemType='marker'/>
              {(isAdmin || currentUser?.uid === selectedMarker.uid) && (
                <>
                  <div className="horizontal-line"></div>
                  <button onClick={handleDelete} className="delete-button">
                    Delete Marker
                  </button>
                </>
              )}
            </>
          ) : type === "img" ? (
            <>
              <h1 className="sidebar-title">{selectedMarker.place}</h1>
              <h4 className="type" style={{ color: '#32CD32'}}>Gallery image</h4>
              <div className="horizontal-line"></div>
              <div className="image-content">
                <img src={selectedMarker.url} alt="Uploaded" />
                <div className="image-info">
                  <p>{selectedMarker.description}</p>
                </div>
              </div>
            </>
          ) : type === "blog" ? (
            <>
              <h1 className="sidebar-title">{selectedMarker.data.place}</h1>
              <h4 className="type" style={{ color: '#1E90FF'}}>Blog</h4>
              <div className="horizontal-line"></div>
              <h1 className="sidebar-title-2">{selectedMarker.data.title}</h1>
              <div className="image-section">
                <div className="images">
                  {Array.isArray(selectedMarker.data.photoURLs) && selectedMarker.data.photoURLs.length > 0 ? (
                    selectedMarker.data.photoURLs.map((url, index) => (
                      <img key={index} src={url} alt="Blog" />
                    ))
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
              <div className="blog-content">
                <div className="blog-info">
                  <p>{parse(selectedMarker.data.body)}</p>
                </div>
                <div className="blog-meta">
                  <span className="date">{selectedMarker.data.date} at {selectedMarker.data.time} JST</span>
                  <p><Link to={`/blog/${selectedMarker.id}`} className="image-link">Link to blogpost</Link></p>
                </div>
              </div>
            </>
          ) : null
        ) : type === "recommendation" ? (
          <form onSubmit={handleFormSubmit}>
            <div>
              <h1 className="sidebar-title">Add recommendation</h1>
              <div className="horizontal-line"></div>
              <label>Place</label>
              <input type="text" name="title" value={formData.title} onChange={handleInputChange} required />
            </div>
            <div>
              <label>Description</label>
              <textarea name="description" value={formData.description} onChange={handleInputChange} required className="description-textarea"></textarea>
            </div>
            <div>
              <label>Image URL (optional)</label>
              <input type="text" name="image" value={formData.image} onChange={handleInputChange}/>
            </div>
            {/*<div>
              <label>Tags (comma separated)</label>
              <input type="text" name="tags" value={formData.tags} onChange={handleInputChange} required />
            </div>*/}
            <div className="button-container-2">
              <button type="submit">Submit</button>
              {isAdmin && (
                <button type="submit" onClick={handleAddVisitedClick} className="add-visited-button">
                  Add Visited
                </button>
              )}
            </div>
          </form>
        ) : (
          <>
          <div className="filter-section">
            <h1 className="sidebar-title">Filter Markers</h1>
            <div className="filter-item">
              <input
                type="checkbox"
                id="showGallery"
                name="gallery"
                checked={showGallery}
                onChange={handleCheckboxChange}
              />
              <img src={GalleryPointer} alt="Gallery" className="filter-icon" />
              <label htmlFor="showGallery" className="filters">Gallery markers</label>
            </div>
            <div className="filter-item">
              <input
                type="checkbox"
                id="showBlog"
                name="blog"
                checked={showBlog}
                onChange={handleCheckboxChange}
              />
              <img src={BlogPointer} alt="Blog" className="filter-icon" />
              <label htmlFor="showBlog">Blog markers</label>
            </div>
            <div className="filter-item">
              <input
                type="checkbox"
                id="showVisited"
                name="visited"
                checked={showVisited}
                onChange={handleCheckboxChange}
              />
              <img src={VisitedPointer} alt="Visited" className="filter-icon" />
              <label htmlFor="showVisited" className="filters">Linus' visited markers</label>
            </div>
            <div className="filter-item">
              <input
                type="checkbox"
                id="showRecommendations"
                name="recommendations"
                checked={showRecommended}
                onChange={handleCheckboxChange}
              />
              <img src={RecommendationPointer} alt="Recommendation" className="filter-icon" />
              <label htmlFor="showRecommendations" className="filters">All recommendations</label>
            </div>
            {currentUser && (
              <div className="filter-item">
                <input
                  type="checkbox"
                  id="showUserRecommendations"
                  name="userRecommendations"
                  checked={showUserRecommendations}
                  onChange={handleCheckboxChange}
                />
                <img src={UserRecommendationPointer} alt="User Recommendations" className="filter-icon" />
                <label htmlFor="showUserRecommendations" className="filters">Your recommendations</label>
              </div>
            )}
          </div>
          <div className="horizontal-line"></div>
          <div className="tips">
            {currentUser ? (
              <div>
                Press on the map to add a recommendation
              </div>
            ) : (
              <div>
                <Link to="/login">Log in</Link> to add recommendations
              </div>
            )}
          </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
